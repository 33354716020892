import InputListener from '../../common/InputListener';
import Bugsnag from '../../common/bugsnag';
import {
  SIGN_IN_WITH_SHOP_MODAL_DISMISSED_COOKIE,
  SIGN_IN_FORM_EMAIL_INPUT_SELECTOR,
  SIGN_UP_FORM_EMAIL_INPUT_SELECTOR,
  CUSTOMER_FIRST_NAME_INPUT_SELECTOR,
  CUSTOMER_LAST_NAME_INPUT_SELECTOR,
} from '../../common/constants';
import {
  createElementLocator,
  createElementVisibilityObserver,
  getAnalyticsTraceId,
  getCookie,
} from '../../common/utils';
import {ClassicCustomerAccountsMonorailTracker} from '../../components/loginButton/analytics';
import ShopLoginButton from '../../components/loginButton/shop-login-button';
import {DefaultComponentAnalyticsContext} from '../../constants/loginDefault';
import {ClassicCustomerAccountsFlowVersion} from '../../types';

/**
 * Initialize Login with Shop (Sign Up) on the customer account creation page.
 */
export async function initCustomerAccountsSignUp() {
  try {
    initClassicCustomerAccountsSignUpForm();
  } catch (error) {
    if (error instanceof Error) {
      Bugsnag.notify(error);
    }
  }
}

/**
 * Initializes the Login with Shop Sign up form for classic customer accounts.
 */
function initClassicCustomerAccountsSignUpForm() {
  const analyticsTraceId = getAnalyticsTraceId();
  const monorailTracker = new ClassicCustomerAccountsMonorailTracker({
    elementName: 'shop-login-button',
    flowVersion: ClassicCustomerAccountsFlowVersion.SignUp,
    analyticsTraceId,
  });
  const inputListenerMap = new WeakMap<HTMLInputElement, InputListener>();
  let shopLoginButton: ShopLoginButton | null = null;

  const elementVisibilityObserver =
    createElementVisibilityObserver<HTMLInputElement>({
      onVisible: addSignInWithShopToInput,
      onFallback: (element) => {
        element.addEventListener('focus', handleInputFocus, {once: true});
        monorailTracker.trackShopPayLoginWithSdkErrorEvents({
          apiKey: '',
          errorCode: 'fallback_to_focus_event',
          errorMessage: 'Fallback to focus event for classic customer accounts',
        });
      },
    });

  createElementLocator<HTMLInputElement>({
    selector: SIGN_UP_FORM_EMAIL_INPUT_SELECTOR,
    onElementFound: (input) => elementVisibilityObserver.observe(input),
  });

  /**
   * Handles the focus event on the email input. This will create the Login with Shop button and remove the focus event listener.
   * @param {Event} event The focus event emitted on the detected email input.
   */
  function handleInputFocus(event: FocusEvent) {
    const input = event.target as HTMLInputElement;
    addSignInWithShopToInput(input);
  }

  /**
   * Determines the correct URL to redirect to when the user Signs in with Shop
   * to be consistent with where they'll land when signing up with email and password.
   * @param {HTMLFormElement} form The form to look up redirect-related inputs from.
   * @param {string} analyticsTraceId The analytics trace ID to pass onward to the redirect endpoint.
   * @returns {string} The URL to redirect to after a successful signup.
   */
  function signUpRedirectUrl(
    form: HTMLFormElement,
    analyticsTraceId: string,
  ): string {
    const checkoutInputUrl = (
      form.elements.namedItem('checkout_url') as HTMLInputElement | undefined
    )?.value;
    const returnInputUrl = (
      form.elements.namedItem('return_url') as HTMLInputElement | undefined
    )?.value;

    /* eslint-disable @typescript-eslint/naming-convention */
    const queryParams = new URLSearchParams({
      analytics_trace_id: analyticsTraceId,
      ...(checkoutInputUrl && {checkout_url: checkoutInputUrl}),
      ...(returnInputUrl && {return_url: returnInputUrl}),
    });

    /* eslint-enable @typescript-eslint/naming-convention */
    const redirectUri = `${
      window.location.origin
    }/account/redirect?${queryParams.toString()}`;

    return redirectUri;
  }

  /**
   * Creates the Login with Shop button and initializes the customer account Sign Up page.
   * @param {HTMLInputElement} input The detected email input element.
   */
  function addSignInWithShopToInput(input: HTMLInputElement) {
    const form = input.form;
    if (!form) {
      Bugsnag.notify(
        new Error('Email form missing for classic customer accounts'),
      );
      return;
    }

    if (inputListenerMap.has(input)) {
      Bugsnag.notify(new Error('Input listener already exists for input'));

      inputListenerMap.get(input)?.destroy();
      inputListenerMap.delete(input);
    }

    // Add hidden analytics trace id to form
    const analyticsTraceIdHiddenInput = document.createElement('input');
    analyticsTraceIdHiddenInput.type = 'hidden';
    analyticsTraceIdHiddenInput.name = 'login_with_shop[analytics_trace_id]';
    analyticsTraceIdHiddenInput.value = analyticsTraceId;
    form.appendChild(analyticsTraceIdHiddenInput);

    // Init login form if it hasn't been initialized yet
    if (!shopLoginButton) {
      shopLoginButton = initCustomerAccountsSignUpPage(analyticsTraceId);
      monorailTracker.trackClassicCustomerAccountsCreateAccountPageImpression();

      shopLoginButton.addEventListener('completed', () => {
        const redirectUri = signUpRedirectUrl(form, analyticsTraceId);
        window.location.assign(redirectUri);
      });
    }

    const firstNameInput = form.querySelector<HTMLInputElement>(
      CUSTOMER_FIRST_NAME_INPUT_SELECTOR,
    );
    const lastNameInput = form.querySelector<HTMLInputElement>(
      CUSTOMER_LAST_NAME_INPUT_SELECTOR,
    );

    shopLoginButton.firstName = firstNameInput?.value;
    shopLoginButton.lastName = lastNameInput?.value;
    shopLoginButton.email = input.value;

    // Add input listener to email input
    inputListenerMap.set(
      input,
      new InputListener(input, (value) => {
        shopLoginButton!.firstName = firstNameInput?.value;
        shopLoginButton!.lastName = lastNameInput?.value;
        shopLoginButton!.email = value;
      }),
    );
  }
}

/**
 * Initialize Login with Shop on the customer account Sign Up page.
 * @param {string} analyticsTraceId The analytics trace ID.
 * @returns {ShopLoginButton} A login button that has been appended to the document.
 */
function initCustomerAccountsSignUpPage(
  analyticsTraceId: string,
): ShopLoginButton {
  const documentDoesNotContainSignInForm =
    document.querySelector(SIGN_IN_FORM_EMAIL_INPUT_SELECTOR) === null;
  const signInWithShopModalWasDismissed =
    getCookie(SIGN_IN_WITH_SHOP_MODAL_DISMISSED_COOKIE) === 'true';
  const shouldAutoOpen =
    documentDoesNotContainSignInForm && !signInWithShopModalWasDismissed;

  const shopLoginButton = document.createElement(
    'shop-login-button',
  ) as ShopLoginButton;
  shopLoginButton.setAttribute('client-id', '');
  shopLoginButton.setAttribute('action', 'default');
  shopLoginButton.setAttribute('version', '2');
  shopLoginButton.setAttribute(
    'flow-version',
    ClassicCustomerAccountsFlowVersion.SignUp,
  );
  shopLoginButton.setAttribute(
    'analytics-context',
    DefaultComponentAnalyticsContext.ClassicCustomerAccounts,
  );
  shopLoginButton.setAttribute('analytics-trace-id', analyticsTraceId);
  shopLoginButton.setAttribute('hide-button', 'true');
  shopLoginButton.setAttribute('email-verification-required', 'true');
  shopLoginButton.setAttribute('disable-sign-up', 'true');
  if (shouldAutoOpen) {
    shopLoginButton.setAttribute('auto-open', 'true');
  }

  document.body.appendChild(shopLoginButton);

  return shopLoginButton;
}
